<template>
  <el-select
      :value="value"
      :disabled="disabled"
      :multiple="multiple"
      placeholder="请选择"
      @input="change($event)"
      :popper-class="popperClass"
      :clearable="clearable"
      filterable>
    <el-option v-if="allowAdd" label="+" value="+" @click="itemAdd"/>
    <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value">
    </el-option>
  </el-select>
</template>

<script>
import { getDicDataByUrl } from '@/api/sys/dict'

export default {
  name: 'GoodSelect',
  props: {
    url: {
      default: '',
      type: String
    },
    popperClass: {
      default: '',
      type: String
    },
    disabled: {
      default: false,
      type: Boolean
    },
    multiple: {
      default: false,
      type: Boolean
    },
    clearable: {
      default: true,
      type: Boolean
    },
    value: {
      default: null,
      type: [String, Number, Array]
    },
    allowAdd: {
      default: false,
      type: Boolean
    },
    itemAdd: {
      default: null,
      type: Function
    },
    itemAdded: {
      default: null,
      type: Function
    },
    needRefresh: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      options: []
    }
  },
  created () {
    this.refresh()
  },
  watch: {
    needRefresh: {
      handler (val) {
        if (val) {
          this.refresh()
        }
      }
    }
  },
  methods: {
    change: function (val) {
      if (val === '+' || (this.multiple && val[val.length - 1] === '+')) {
        this.itemAdd()
      } else {
        this.$emit('input', val)
      }
    },
    refresh: function () {
      if (this.url) {
        getDicDataByUrl(this.url).then(res => {
          this.options = res.data
        })
      }
      this.$on('handleOptionClick', this.handleOptionSelect)
    }
  }
}
</script>
