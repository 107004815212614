import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/sys/dict/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}
export const remove = (ids) => {
  return request({
    url: '/sys/dict/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const add = (row) => {
  return request({
    url: '/sys/dict/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/sys/dict/submit',
    method: 'post',
    data: row
  })
}

export const getDict = (id) => {
  return request({
    url: '/sys/dict/detail',
    method: 'get',
    params: {
      id
    }
  })
}
export const getDictTree = () => {
  return request({
    url: '/sys/dict/tree?code=DICT',
    method: 'get'
  })
}

export const getDicData = (code) => {
  return request({
    url: '/sys/dict/code/' + code,
    method: 'get'
  })
}

export const getMaxKey = (code) => {
  return request({
    url: '/sys/dict/max/' + code,
    method: 'get'
  })
}

export const getDicDataByUrl = (url) => {
  return request({
    url: url,
    method: 'get'
  })
}
