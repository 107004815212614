<template>
  <basic-container>
    <el-row :gutter="40">
      <el-col :span="24">
        <el-form :inline="true" :model="search" class="demo-form-inline">
          <el-form-item label="订单编号：">
            <el-input v-model="search.orderSn" placeholder="请输入订单编号"></el-input>
          </el-form-item>
          <el-form-item label="商品类别：">
            <good-select v-model="search.typeId" url="/mall/goodstype/dict"></good-select>
          </el-form-item>
          <el-form-item label="时间范围：">
            <el-date-picker
                v-model="search.dateRange"
                type="daterange"
                value-format="yyyy-MM-dd"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="商品学名：">
            <el-select
                v-model="search.tradeName"
                filterable
                remote
                reserve-keyword
                placeholder="拼音首字母"
                :remote-method="filterName"
                :loading="loadingNames">
              <el-option
                  v-for="item in names"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="searchChange">查询</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="14">
        <h3>销售订单统计</h3>
        <el-table
            class="good-table"
            border
            :data="statGoodsName"
            v-loading.body="listLoading"
            element-loading-text="拼命加载中"
            fit>
          <el-table-column
              prop="typeName"
              label="品类">
          </el-table-column>
          <el-table-column
              prop="goodsName"
              label="商品名">
          </el-table-column>
          <el-table-column
              prop="amountStr"
              label="重量/数量">
          </el-table-column>
          <el-table-column
              prop="payAmount"
              label="销售额(元)">
          </el-table-column>
        </el-table>
        <div v-show="!listLoading" class="end pagination-container">
          <el-pagination
              :current-page.sync="page.currentPage"
              background
              @current-change="currentChange"
              @size-change="sizeChange"
              :page-sizes="[10,20,30,50]"
              :page-size="page.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
          </el-pagination>
        </div>
      </el-col>
      <el-col :span="10">
        <h3>支付流水统计</h3>
        <el-table
            class="good-table"
            border
            :data="statPay"
            fit>
          <el-table-column
              prop="type"
              label="支付类型">
          </el-table-column>
          <el-table-column
              prop="cnt"
              label="笔数">
          </el-table-column>
          <el-table-column
              prop="sum"
              label="金额(元)">
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

  </basic-container>
</template>

<script>
import { getNames } from '@/api/mall/goodsname'
import GoodSelect from '@/components/good-select'
import { getOrderInfo, groupName, groupPay } from '@/api/mall/stat'
import { getToken } from '@/util/auth'

export default {
  components: { GoodSelect },
  data () {
    return {
      listLoading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      total: null,
      statGoodsName: [],
      statPay: [],
      search: {
        start: null,
        end: null,
        tradeName: null,
        dateRange: []
      },
      loadingNames: false,
      names: [],
      pickerOptions: {
        shortcuts: [
          {
            text: '今日',
            onClick (picker) {
              const start = new Date()
              const end = new Date()
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '昨日',
            onClick (picker) {
              const yesterday = new Date(new Date() - 1000 * 60 * 60 * 24)
              picker.$emit('pick', [yesterday, yesterday])
            }
          }, {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 6)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }]
      }
    }
  },
  created () {
    this.onLoad(this.page)
    this.loadPay()
  },
  methods: {
    loadPay () {
      groupPay(this.search).then(res => {
        if (res.success) {
          this.statPay = res.data
        } else {
          if (res.code === 4103) {
            window.app.refreshToken(getToken())
          }
        }
      })
    },
    filterName (query) {
      if (query !== '') {
        this.loadingNames = true
        getNames(query).then(res => {
          if (res.success) {
            this.loadingNames = false
            this.names = res.data
          } else {
            if (res.code === 4103) {
              window.app.refreshToken(getToken())
            }
          }
        })
      } else {
        this.names = []
      }
    },
    searchReset () {
      this.search = {}
      this.onLoad(this.page)
    },
    searchChange () {
      if (this.search.dateRange && this.search.dateRange.length === 2) {
        this.search.start = this.search.dateRange[0]
        this.search.end = this.search.dateRange[1]
      }
      this.onLoad(this.page)
      this.loadPay()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange () {
      this.onLoad(this.page)
    },
    currentChange (val) {
      this.page.currentPage = val
      this.onLoad(this.page)
    },
    sizeChange (val) {
      this.page.pageSize = val
      this.onLoad(this.page)
    },
    onLoad (page) {
      this.listLoading = true
      groupName(page.currentPage, page.pageSize, this.search).then(res => {
        if (res.success) {
          this.listLoading = false
          const data = res.data
          this.total = data.total
          this.statGoodsName = data.records
        } else {
          if (res.code === 4103) {
            window.app.refreshToken(getToken())
          }
        }
      })
    },
    handleCurrentRowChange (row) {
      getOrderInfo(row.id).then(res => {
        if (res.success) {
          this.order = res.data
        } else {
          if (res.code === 4103) {
            window.app.refreshToken(getToken())
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
  i {
    margin-right: 15px;
  }

  font-size: 24px;
}

.pagination-container {
  margin-top: 15px;
}
</style>
